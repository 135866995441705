@use "~styles/breakpoint" as *;

.studio {
  margin-bottom: 10rem;

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 7.9rem;
    flex-wrap: wrap;

    @include breakpoint($mobile) {
      justify-content: flex-start;
      margin-bottom: 4rem;
    }
  }

  &__text {
    width: 30rem;
    max-width: 30rem;
    flex: 1 0 auto;
    margin-bottom: 4rem;

    @include breakpoint($mobile) {
      width: 100%;
      max-width: unset;
    }
  }

  &__image {
    max-width: min(104rem, 100%);
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    row-gap: 5rem;
    @include breakpoint($desktop-hd) {
      margin-left: auto;
    }

    &-text {
      max-width: 58.8rem;
    }

    img {
      width: 100%;
    }
  }

  &__title {
    width: calc(
      100vw - $page-padding * 2 - ($gap + $column-width) * 2 -
        (58.4rem + 14rem + 30.8rem + 9rem) - $gap/2
    );
    padding-right: $gap;
    white-space: nowrap;

    @include breakpoint($mobile) {
      width: 100%;
      padding: 0;
    }
  }

  &__logos {
    max-width: 104rem;
    width: 100%;
    flex: 1 0 auto;
    display: flex;
    flex-wrap: wrap;
    row-gap: 4rem;
    column-gap: 4rem;
    margin-top: 12rem;
    margin-bottom: 6rem;
    justify-content: space-between;

    @include breakpoint($mobile) {
      max-width: 100%;
      margin-top: 4.2rem;
      margin-bottom: 4rem;
    }
  }
}
