@use "~styles/breakpoint" as *;
@use "~styles/colors" as *;

.head-text {
  flex: 0 0 auto;

  @each $key, $value in $colors {
    &--#{$key} {
      color: $value;
    }
  }

  &__link {
    margin-top: 7.3rem;

    @include breakpoint($mobile) {
      margin-top: 3.7rem;
    }

    &::after {
      top: 2.6rem;
    }
  }
}
