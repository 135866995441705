$font-core-sans: "Core Sans C";

@font-face {
  font-family: $font-core-sans;
  src: url("../../public/fonts/coresansc-45regular-webfont.woff2")
      format("woff2"),
    url("../../public/fonts/coresansc-45regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $font-core-sans;
  src: url("../../public/fonts/coresansc-65bold-webfont.woff2") format("woff2"),
    url("../../public/fonts/coresansc-65bold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
