@use "~styles/breakpoint" as *;

.skills {
  line-height: 3.4rem;
  min-width: 30.8rem;
  margin-right: 9rem;

  @include breakpoint($mobile) {
    font-size: 1.4rem;
    line-height: 3rem;
    margin-right: 0;
  }
}
