@use "~styles/colors" as *;
@use "~styles/mixins" as *;

.contact {
  $self: &;
  width: 18.6rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &--white {
    #{ $self }__link {
      color: color(gray3);

      &::after {
        background-color: color(gray3);
      }
    }
  }

  &__link {
    position: relative;
    color: color(gray1);
    display: block;
    &:last-child {
      font-weight: bold;
    }

    &::after {
      position: absolute;
      top: 2.6rem;
      left: 0;
      content: " ";
      width: 0;
      height: 0.2rem;
      background-color: color(gray1);
      @include transition-medium;
    }

    &:hover::after {
      width: 100%;
    }
  }
}
