@use "~styles/breakpoint" as *;
@use "~styles/colors" as *;
@use "~styles/mixins" as *;

.main-page {
  padding: 0 $page-padding;
  @include get-vh;
  -webkit-overflow-scrolling: touch;

  @include breakpoint($mobile) {
    padding: 0 $page-padding-mobile;
  }

  &__section {
    margin-top: 8rem;
    @include breakpoint($mobile) {
      margin-top: 4rem;
    }
  }

  &__link {
    margin-bottom: 9.8rem;
    @include breakpoint($mobile) {
      margin-bottom: 4rem;
    }
  }

  &__video {
    &-wrapper {
      position: relative;

      @include breakpoint($mobile) {
        width: auto;
        display: flex;
        justify-content: center;
        overflow: hidden;
        border-radius: 2rem;
      }
    }

    &-sound-button {
      background-image: url(../../../public/sound_off.svg);
      background-position: center;
      width: 3.9rem;
      height: 4.9rem;
      position: absolute;
      right: 6rem;
      bottom: 4rem;
      cursor: pointer;
      background-repeat: no-repeat;
      padding: 2rem;
      @include transition-medium(background-image);
      transform-style: preserve-3d;

      @include breakpoint($desktop) {
        right: 4rem;
      }

      @include breakpoint($mobile) {
        right: 4rem;
        bottom: 4rem;
        padding: 1rem;
        width: 2.9rem;
        height: 3.9rem;
      }

      &--active {
        background-image: url(../../../public/sound_on.svg);
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        background-color: color(secondary);
        transform: translate3d(-50%, -50%, -1px);
        border-radius: 50%;
        @include transition-medium;
      }

      &:hover::before {
        width: 7rem;
        height: 7rem;
      }
    }

    @include transition-medium;

    width: calc(100vw - $page-padding * 2);
    left: $page-padding;
    border-radius: 2rem;

    @include breakpoint($mobile) {
      width: 200vw;
    }

    &--fullscreen {
      @include breakpoint($mobile, "min") {
        width: 100vw;
        margin-left: -$page-padding;
        border-radius: 0;
      }
    }
  }
}
