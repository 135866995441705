@use "~styles/breakpoint" as *;

.header-texts {
  padding-top: 14.6rem;
  margin-bottom: 12.7rem;
  display: flex;
  flex-wrap: wrap;
  column-gap: $gap;
  row-gap: 6rem;

  @include breakpoint($mobile) {
    margin-bottom: 6rem;
  }
}
