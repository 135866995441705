@use "~styles/breakpoint" as *;
@use "~styles/colors" as *;
@use "~styles/mixins" as *;

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: $gap;
  padding-top: 3rem;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  padding: 3rem calc($gap + $column-width + $page-padding) 1rem;
  height: 7rem;
  @include transition-fast(top);

  @include breakpoint($desktop) {
    padding: 3rem calc($page-padding) 1rem;
  }

  &--hidden {
    top: -7rem;
  }
}
