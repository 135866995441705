@use "~styles/colors" as *;
@use "~styles/breakpoint" as *;

.footer {
  display: flex;
  align-items: flex-end;
  column-gap: $gap;
  padding-bottom: 6rem;

  @include breakpoint($mobile) {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 0;
  }

  &__info-wrapper {
    @include get-width(6, false);
    @include breakpoint($mobile) {
      margin-bottom: 4rem;
    }
  }

  &__copy {
    @include get-width(2, false);
    text-align: right;
    white-space: nowrap;
    padding-bottom: 2rem;
    @include breakpoint($mobile) {
      margin-left: auto;
      width: 100%;
    }
  }

  &__letswork {
    @include breakpoint($mobile) {
      margin-bottom: 10rem;
    }
  }
}
