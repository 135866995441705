@use "sass:math";

$page-padding: 4vw;
$page-padding-mobile: 1.6vw;
$grid-columns: 12;

$column-width: math.div(90, $grid-columns) * 1vw;
$gap: 2.4rem;

@function calculate-space($n) {
  @return calc(($n * $column-width) + (($n - 1) * $gap));
}

@mixin get-width($n, $with-margin: true) {
  width: calculate-space($n);

  @if $with-margin == true {
    margin: 0 $gap;
  }
}

// max content width
$max-width: 149.6rem;

// breakpoints
$desktop-hdp: "xxl";
$desktop-hd: 1920px;
$desktop: 1400px;
$mobile: 1000px;
$mobile-xs: 320px;

@mixin breakpoint($breakpoint, $from: "max") {
  $value: if($from == "max", $breakpoint, $breakpoint + 1);

  @if $breakpoint == $desktop-hdp {
    @media only screen and (min-width: $desktop-hd) {
      @content;
    }
  }

  @if $breakpoint == $desktop-hd {
    @media only screen and (#{ $from }-width: $value) {
      @content;
    }
  }

  @if $breakpoint == $desktop {
    @media only screen and (#{ $from }-width: $value) {
      @content;
    }
  }

  @if $breakpoint == $mobile {
    @media only screen and (#{ $from }-width: $value) {
      @content;
    }
  }

  @if $breakpoint == $mobile-xs {
    @media only screen and (#{ $from }-width: $value) {
      @content;
    }
  }
}
