@use "~styles/breakpoint" as *;
@use "~styles/colors" as *;
@use "~styles/mixins" as *;

.menu-popup {
  color: color(gray3);
  @include breakpoint($mobile) {
    z-index: 2;
  }

  &__burger {
    cursor: pointer;
    z-index: 2;
    position: relative;
    display: block;
    width: 2.1rem;
    height: 2.1rem;
    @include transition-medium;

    > span {
      position: absolute;
      left: 0;
      display: block;
      width: 100%;
      height: 0.4rem;
      background-color: color(primary);
      @include transition-medium;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: 50%;
        transform: translateY(-50%);
        width: 70%;
      }

      &:nth-child(3) {
        bottom: 0;
      }
    }

    &:hover:not(&--active) {
      > span {
        width: 70%;

        &:nth-child(2) {
          width: 100%;
        }
      }
    }

    &--active {
      width: 2.6rem;
      height: 2.6rem;

      > span {
        background-color: color(gray3);
        width: 100%;
        bottom: unset;
        transform-origin: center center;
        border-radius: 0.2rem;

        &:nth-child(1) {
          transform: translate(-50%, -50%) rotate(-45deg);
          left: 50%;
          top: 50%;
        }
  
        &:nth-child(2) {
          opacity: 0;
        }
  
        &:nth-child(3) {
          transform: translate(-50%, -50%) rotate(45deg);
          left: 50%;
          top: 50%;
        }
      }

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  &__content {
    padding: 0 calc($gap + $column-width + $page-padding) 10rem;
    overflow: auto;
    position: fixed;
    background-color: color(primary);
    width: 100vw;
    @include get-vh;
    z-index: 1;
    top: -100vh;
    left: 0;
    @include transition-medium;

    @include breakpoint($desktop) {
      padding: 0 $page-padding;
    }

    @include breakpoint($mobile) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 $page-padding;
    }

    &--active {
      top: 0;
    }
  }

  &__logo {
    margin-top: 3rem;
  }

  &__menu {
    margin-top: 14.8rem;
    display: flex;

    @include breakpoint($mobile) {
      flex: 1 0 auto;
      justify-content: space-between;
      flex-direction: column-reverse;
      margin-top: 6rem;
    }

    @include breakpoint($mobile-xs) {
      margin-top: 3rem;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include breakpoint($mobile) {
      margin-bottom: 6rem;
    }

    @include breakpoint($mobile-xs) {
      margin-bottom: 3rem;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include breakpoint($mobile) {
      max-height: unset;
      margin-bottom: 0;
      flex: 1 0 auto;
      justify-content: center;
      row-gap: 2rem;
    }

    @include breakpoint($mobile-xs) {
      row-gap: 0;
    }
  }

  &__link {
    position: relative;
    margin-bottom: 12.5rem;
    box-sizing: border-box;
    display: block;
    overflow: hidden;
    flex: 0 1 auto;

    &:last-child {
      margin-bottom: 0;
    }

    &::after {
      position: absolute;
      top: 6rem;
      left: 0;
      content: " ";
      width: 0;
      height: 0.3rem;
      background-color: color(gray3);
      @include transition-medium;
    }

    &:hover::after {
      width: 100%;
    }

    @include breakpoint($mobile) {
      margin-bottom: 0;
    }

    @include breakpoint($mobile-xs) {
      font-size: 3rem;
    }
  }

  &__letswork {
    margin-right: 8rem;
    @include breakpoint($mobile) {
      margin-top: 4rem;
      margin-bottom: 4rem;
    }
  }
}
