@use "~styles/breakpoint" as *;
@use "~styles/colors" as *;
@use "~styles/mixins" as *;

.tile {
  $mobile-margin-bottom: 8rem;

  color: color(primary);
  @include breakpoint($mobile) {
    margin-bottom: $mobile-margin-bottom;
  }

  &__image {
    &-wrapper {
      position: relative;
      overflow: hidden;
      margin-bottom: 2.1rem;
      border-radius: 0;
      cursor: pointer;
      @include transition-medium(border-radius);

      &:hover {
        border-radius: 2rem;
      }
    }

    max-width: 100%;
    transform: scale(1);
    @include transition-medium(transform);

    &:hover {
      transform: scale(1.1);
    }
  }

  &__mouse {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    background-color: color(secondary);
    width: 0;
    height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @include transition-medium;
    transition-property: width, height;
    overflow: hidden;
    pointer-events: none;
    transform: translate(-50%, -50%);

    &--active {
      width: 8rem;
      height: 8rem;
    }

    @include breakpoint($mobile) {
      display: none;
    }
  }

  &__link {
    @include breakpoint($mobile) {
      margin-bottom: -$mobile-margin-bottom;
    }
  }
}
