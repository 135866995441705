@use "~styles/colors" as *;
@use "~styles/mixins" as *;

.anchor-link {
  position: relative;

  &::after {
    position: absolute;
    top: 2.2rem;
    left: 0;
    content: " ";
    width: 0;
    height: 0.2rem;
    background-color: color(primary);
    @include transition-medium;
  }

  &:hover::after {
    width: 100%;
  }
}

.head-text  {
  &--secondary {
    .anchor-link::after {
      background-color: color(secondary);
    }
  }
}
