/* stylelint-disable */
$colors: (
  primary: #171717,
  secondary: #7636ff,
  gray1: #646464,
  gray2: #b1b1b1,
  gray3: #ffffff,
);

@function color($color, $alpha: 1) {
  @if $alpha != 1 {
    @return rgb(map-get($colors, $color), $alpha);
  }
  @return map-get($colors, $color);
}

:export {
  @each $key, $value in $colors {
    #{$key}: $value;
  }
}
