@use "~styles/breakpoint" as *;

.letswork {
  @include get-width(2, false);

  @include breakpoint($mobile) {
    min-width: 15.9rem;
  }

  &__text {
    max-width: 15.9rem;
  }

  &__line {
    display: block;
    width: 100%;
  }
}
