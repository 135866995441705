@use "~styles/breakpoint" as *;
@use "~styles/mixins" as *;

.case-page {
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  .layout {
    margin: 0 $page-padding;
  }

  &__cover {
    width: 100%;
    margin-bottom: 7.9rem;

    @include breakpoint($mobile) {
      margin-bottom: 3.8rem;
    }
  }

  &__content {
    display: flex;
    column-gap: $gap;
    margin-bottom: 4.6rem;

    @include breakpoint($mobile) {
      flex-direction: column;
    }
  }

  &__title {
    font-weight: bold;

    @include get-width(3, false);

    @include breakpoint($desktop) {
      @include get-width(4, false);
    }

    @include breakpoint($mobile) {
      margin-bottom: 3.8rem;
      width: 100%;
    }
  }

  &__subtitle {
    vertical-align: super;
    margin-left: 1rem;
  }

  &__text {
    margin-bottom: 4.6rem;

    @include breakpoint($mobile) {
      font-size: 1.8rem;
      line-height: 3.4rem;
      width: 100%;
      margin-bottom: 3.8rem;
    }

    &-wrapper {
      flex: 1 0 auto;
      width: 0;
      max-width: 62.5rem;

      @include breakpoint($mobile) {
        width: 100%;
      }
    }
  }

  &__other-title {
    margin-bottom: 4.2rem;

    @include breakpoint($mobile) {
      margin-bottom: 2rem;
    }
  }

  &__tiles {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 7.9rem;
    @include breakpoint($mobile) {
      margin-bottom: 3.8rem;
    }
  }

  &__tile {
    height: fit-content;

    &-image {
      display: block;
      width: 100%;
      height: 100%;
    }

    &-content {
      &--active {
        cursor: pointer;
      }
    }

    &--size1 {
      width: 100%;
    }

    &--size2 {
      width: 50%;
      @include breakpoint($mobile) {
        width: 100%;
      }
    }
  }
}
