@import "~styles/breakpoint";
@import "~styles/colors";
@import "~styles/mixins";
@import "~styles/reset";
@import "~styles/typography";

html {
  font-size: 10px;
  font-family: $font-core-sans;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

body {
  -webkit-overflow-scrolling: touch;
  @include breakpoint($mobile, "min") {
    @include custom-scroll-bar;
  }
}

.font54 {
  font-size: 5.4rem;
  line-height: 6.4rem;
}

.font34 {
  font-size: 3.4rem;
  line-height: 4.7rem;
}

.font32 {
  font-size: 3.2rem;
  line-height: 3.6rem;
}

.font26 {
  font-size: 2.6rem;
  line-height: 3.6rem;
}

.font24 {
  font-size: 2.4rem;
  line-height: 5.2rem;
}

.font22 {
  font-size: 2.2rem;
  line-height: 3.6rem;
}

.font20 {
  font-size: 2rem;
  line-height: 4.7rem;
}

.font18 {
  font-size: 1.8rem;
  line-height: 2.8rem;
}

.font16 {
  font-size: 1.6rem;
  line-height: 1.8rem;
}

.font14 {
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.font-primary {
  color: color(primary);
}

.font-secondary {
  color: color(secondary);
}

.font-gray1 {
  color: color(gray1);
}

.font-gray2 {
  color: color(gray2);
}

.font-gray3 {
  color: color(gray3);
}

@for $i from 1 through $grid-columns {
  .size#{$i} {
    @include get-width($i, false);
    @include breakpoint($desktop) {
      @include get-width($i + 1, false);
    }
    @include breakpoint($mobile) {
      width: 100%;
    }
  }
}

a {
  text-decoration: none;
}

.layout {
  padding: 0 calc($gap + $column-width);

  @include breakpoint($desktop) {
    padding: 0;
  }

  @include breakpoint($mobile) {
    padding: 0 1rem;
  }
}

.z-index-top {
  position: relative;
  z-index: 1;
}
