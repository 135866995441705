@use "~styles/breakpoint" as *;
@use "~styles/colors" as *;
@use "~styles/mixins" as *;

.process-step {
  &__title {
    position: relative;
    width: 100%;
    border-bottom: 0.2rem solid color(gray2);
    padding-bottom: 1rem;
    margin-bottom: 3rem;
    cursor: pointer;
    user-select: none;

    @include breakpoint($mobile) {
      font-size: 2.2rem;
      line-height: 2.8rem;
    }

    &::after {
      position: absolute;
      content: "";
      background-image: url(../../../public/arrow-right-top.svg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      top: 0;
      width: 3rem;
      height: 3rem;
      padding: 0.7rem;
      right: 0;
      transform: scaleY(1);
      transform-origin: center center;
      @include transition-medium;
    }

    &::before {
      position: absolute;
      content: "";
      width: 0;
      height: 0.2rem;
      background-color: color(secondary);
      left: 0;
      bottom: -0.1rem;
      @include transition-basic(width);
    }

    &:hover::before {
      width: 100%;
    }

    &--active {
      &::after {
        transform: scaleY(-1);
        background-image: url(../../../public/arrow-right-top-gray.svg);
      }
    }
  }

  &__text {
    max-width: 58.4rem;
    margin-right: 14rem;
    @include breakpoint($desktop) {
      margin-left: auto;
      margin-right: auto;
    }
    @include breakpoint($mobile) {
      font-size: 1.6rem;
      line-height: 2.8rem;
      max-width: unset;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 1.6rem;
    }
  }

  &__content {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 5.5rem;

    @include breakpoint($mobile) {
      flex-direction: column;
    }

    &-wrapper {
      overflow: hidden;
      @include transition-medium(height);
    }
  }
}
