@use "~styles/breakpoint" as *;

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 auto;

  @include breakpoint($mobile) {
    flex: 0 0 auto;
  }

  &__links {
    display: flex;
    column-gap: 10.5rem;
    align-items: center;
    flex: 1 0 auto;

    @include breakpoint($mobile) {
      display: none;
    }
  }
}
