@use "~styles/breakpoint" as *;

.grid {
  display: flex;
  column-gap: $gap;
  margin-bottom: 8.8rem;

  @include breakpoint($mobile) {
    flex-direction: column;
    margin-bottom: 0;
  }
}
