@use "~styles/breakpoint" as *;

.logo {
  @include get-width(3, false);

  @include breakpoint($desktop) {
    @include get-width(4, false);
  }

  &__image {
    width: 13.2rem;
    cursor: pointer;
  }
}
