@use "~styles/breakpoint" as *;

.link {
  display: flex;
  align-items: center;
  width: fit-content;
  color: inherit;
  fill: inherit;

  @include breakpoint($mobile) {
    font-size: 2rem;
    line-height: 2.6rem;
  }

  &--url {
    cursor: pointer;

    &:hover {
      color: inherit;
    }
  }

  &__icon {
    &--size18 {
      margin: 0.6rem 0 0 0.6rem;
    }

    &--size34 {
      margin: 0.8rem 0 0 1rem;
    }
  }
}
